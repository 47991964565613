import { useMutation, useQueryClient } from "@tanstack/react-query";
import { User } from "@microsoft/microsoft-graph-types-beta";
import { editUser } from "../services/userManagementService";

const useEditUser = ({userId}:{userId: string}) => {
  const queryClient = useQueryClient();

  const editUserMutation = useMutation({
    mutationKey: ["mutate-user", userId, "edit"],
    mutationFn: (
      updatedUser: Pick<
        User,
        | "givenName"
        | "surname"
        | "companyName"
        | "department"
        | "jobTitle"
        | "id"
        | "displayName"
      >
    ) => {
      return editUser(updatedUser)
        .then((response) => {
          if (response.statusCode === 200) {
            queryClient.invalidateQueries({
              queryKey: ["user", userId],
              exact: true,
            });
            queryClient.invalidateQueries({
              queryKey: ["all-users"],
              exact: true,
            });
          }
          return {status: response.statusCode}
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });
  return {
    editUserMutation
  }
}

export default useEditUser


