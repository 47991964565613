import { FormattedMessage } from "gatsby-plugin-intl";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import useUserGroups from "../../hooks/useUserGroups";
import filterRoles from "../../utils/filterRoles";
import filterSports from "../../utils/filterSports";
import getGroupDisplayName from "../../utils/getGroupDisplayName";
import { ExtendedUser } from "../../types/user-management";
import useInvitation from "../../hooks/useInvitation";
import { useIsMutating } from "@tanstack/react-query";

const PermissionsTableCell = ({ user }: { user: ExtendedUser }) => {
  const { id, invitationId } = user;

  const { invitationsQuery, invitationsQueryData } = useInvitation({
    id: invitationId ?? 0,
  });

  const { userGroupsData, userGroupsQuery } = useUserGroups({
    userId: id ?? "",
  });

  const isMutating = useIsMutating({mutationKey: ['mutate-group-member', id]})

  const allRoleIds = [
    process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID,
    process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID,
    process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID,
    process.env.GATSBY_WIPS_GROUP_ID,
    process.env.GATSBY_PPP_USER_MANAGERS_GROUP_ID,
    process.env.GATSBY_PPP_USERS_GROUP_ID,
  ];

  const roles =
    invitationsQueryData && invitationsQueryData[0]
      ? invitationsQueryData[0].roles
      : filterRoles(userGroupsData);
  const sports =
    invitationsQueryData && invitationsQueryData[0]
      ? invitationsQueryData[0].sports
      : filterSports(userGroupsData);

  const permissionsList = [roles, sports].flat();

  const numberOfPermissions = permissionsList.length;

  const [showAll, setShowAll] = useState<boolean>(false);

  if (invitationsQuery.isLoading || userGroupsQuery.isFetching || isMutating)
    return (
      <Spinner animation="border" variant="secondary" role="status">
        <span className="visually-hidden">
          <FormattedMessage id="loading" />
        </span>
      </Spinner>
    );

  return (
    <div className="d-flex align-items-end">
      {(invitationsQuery.error || userGroupsQuery.error) && (
        <FormattedMessage id="error" defaultMessage="An error has occurred" />
      )}

      <ul className="list-unstyled mb-0">
        {numberOfPermissions > 3 && !showAll
          ? permissionsList.slice(0, 3).map((permission: any) =>
            {
              const permissionId = permission.id || permission;
              return  (
                <li key={permissionId}>
                  <span
                    className={`badge ${
                      allRoleIds.includes(permissionId)
                        ? "bg-primary"
                        : "bg-secondary"
                    }`}
                  >
                    {getGroupDisplayName(permissionId, permission.displayName)}
                  </span>
                </li>
              )
            })
          : permissionsList.map(
              (permission: any) =>
              { 
                const permissionId = permission.id || permission;
                return permissionId != undefined && (
                  <li key={permissionId}>
                    <span
                      className={`badge ${
                        allRoleIds.includes(permissionId)
                          ? "bg-primary"
                          : "bg-secondary"
                      }`}
                    >
                      {getGroupDisplayName(
                        permissionId,
                        permission?.displayName
                      )}
                    </span>
                  </li>
                )
              }
                
            )}
      </ul>
      <div aria-live="polite" aria-atomic="true" className="visually-hidden">
        {showAll && (
          <span>{numberOfPermissions - 3} more permissions shown</span>
        )}
      </div>
      {numberOfPermissions > 3 && showAll && (
        <button
          type="button"
          className="btn pb-0 text-decoration-underline"
          onClick={() => setShowAll(false)}
        >
          Show less
        </button>
      )}
      {numberOfPermissions > 3 && !showAll && (
        <button
          type="button"
          className="btn pb-0 text-decoration-underline"
          onClick={() => setShowAll(true)}
        >
          + {numberOfPermissions - 3} more
        </button>
      )}
    </div>
  );
};

export default PermissionsTableCell;
