import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import { Spinner } from "react-bootstrap";

import useUserInvitations from "../../hooks/useUserInvitations";
import { ExtendedUser } from "../../types/user-management";
import useInvitation from "../../hooks/useInvitation";

function InvitationStatus({ user }: { user: ExtendedUser }) {
  if (!user) {
    return <></>;
  }

  const isInvitationExpired = (expiryDate: number) => {
    return expiryDate < Date.now();
  };

  if (user.invitationId) {
    const { invitationsQuery } = useInvitation({
      id: user.invitationId,
    });
  
    if (invitationsQuery.isLoading) {
      return (
        <Spinner animation="border" variant="secondary" role="status">
          <span className="visually-hidden">
            <FormattedMessage id="loading" />
          </span>
        </Spinner>
      );
    } else {
      if (invitationsQuery.data) {
          if (
            isInvitationExpired(
              Date.parse(invitationsQuery.data[0].expiryDate)
            )
          ) {
            return <span className="badge bg-primary">Expired</span>;
          }
          return <span className="badge bg-accent text-black">Pending</span>;
        }
      }
    } else {  
      if (user.id) {
        const { userInvitationsQuery } = useUserInvitations({
          userId: user.id,
        });

        if (userInvitationsQuery.isLoading) {
          return (
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          );
        } else {
          if (userInvitationsQuery.data) {
            if (
              userInvitationsQuery.data.length === 0 ||
              userInvitationsQuery.data[0].acceptedDate
            ) {
              return <span className="badge bg-success">Accepted</span>;
            }

            if (
              userInvitationsQuery.data[0] &&
              !userInvitationsQuery.data[0].acceptedDate
            ) {
              if (
                isInvitationExpired(
                  Date.parse(userInvitationsQuery.data[0].expiryDate)
                )
              ) {
                return <span className="badge bg-primary">Expired</span>;
              }
              return <span className="badge bg-accent text-black">Pending</span>;
            }
          }
        }
      }
  } 

  return <span className="badge bg-secondary">Unknown</span>;
}

export default InvitationStatus;
