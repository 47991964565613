import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useInvitation from "../../hooks/useInvitation";
import { ExtendedUser } from "../../types/user-management";
import dayjs from "dayjs";
import { regenerateInvitation } from "../../services/userManagementService";

function GenerateInvitation({ user }: { user: ExtendedUser }) {
  if (!user) {
    return <></>;
  }

  const { invitationsQuery, invitationsQueryData } = useInvitation({
    id: user.invitationId
  });

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [invitation, setInvitation] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const handleRegenerate = () => {
    
    regenerateInvitation(user.invitationId)
      .then(() => {
        invitationsQuery.refetch().then((response) => {
          if (response.data && response.data[0]) {
            setInvitation(response.data[0].magicLink)
            const expiry = dayjs(response.data[0].expiryDate).format('DD/MM/YYYY H:mm');
            setExpiryDate(expiry);
          }
        });
      })
      .catch((error) => console.error(error));

  };

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(invitation);
    e.target.focus();
    setCopiedToClipboard(true);
  };

  useEffect(() => {
    if (invitationsQueryData && invitationsQueryData[0]) {
      setInvitation(invitationsQueryData[0].magicLink)
      const expiry = dayjs(invitationsQueryData[0].expiryDate).format('DD/MM/YYYY H:mm');
      setExpiryDate(expiry);
    }
  }, [invitationsQueryData]);


  if (invitationsQueryData && invitationsQueryData[0]) {
    return (
      <div className="row">
        <div className="col-8 mb-4">
          <h3>Invitation</h3>
          <div className="mb-2 d-flex">
            <div className="border rounded p-2 bg-light-subtle">
              {invitation}
            </div>
            <Button className="btn btn-secondary" onClick={copyToClipboard}>
              {copiedToClipboard ? "Copied" : "Copy"}
            </Button>
          </div>
          <h3>Expiry</h3>
          <div className="border rounded p-2 bg-light-subtle mb-2">
            {expiryDate}
          </div>
          <Button
            variant="outline-secondary"
            onClick={handleRegenerate}
          >
            Regenerate Invitation
          </Button>
          
        </div>
      </div>
    );
  }

  return null;
}

export default GenerateInvitation;
