import React, { useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SearchUsers from "../../components/user-management/search-users";
import UsersList from "../../components/user-management/users-list";

const UserManagement = () => {
  const [searchName, setSearchName] = useState<string | undefined>();

  return (
    <Layout mainClassName="container-fluid">
      <>
        <div className="row justify-content-between mb-3">
          <div className="col-12 col-md-8">
            {" "}
            <h1 className="block-title">
              <FormattedMessage
                id="user_management"
                defaultMessage="User Management"
              />
            </h1>
          </div>
          <div className="col-12 col-md-4">
            <div className="d-flex gap-3 justify-content-end">
              <Link
                className="btn btn-secondary skew-none"
                to={"/user-management/manage-groups"}
              >
                <FormattedMessage
                  id="manage_groups"
                  defaultMessage="Manage groups"
                />
              </Link>
              <Link
                className="btn btn-secondary skew-none"
                to={"/user-management/new-user"}
              >
                <FormattedMessage id="user_add" defaultMessage="Add new user" />
                <FontAwesomeIcon icon={faPlus} className="ms-2 fa-lg" />
              </Link>
            </div>
          </div>
        </div>
        <SearchUsers setSearchName={setSearchName} searchName={searchName} />
        <UsersList searchName={searchName} />
      </>
    </Layout>
  );
};

export default UserManagement;
