import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import { Spinner } from "react-bootstrap";
import GenerateInvitation from "../generate-new-invitation";
import InvitationStatus from "../invitation-status";
import PermissionsTableCell from "../permissions-table-cell";
import UserDetails from "./user-details";
import { ExtendedUser } from "../../../types/user-management";
import useInvitation from "../../../hooks/useInvitation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const ViewUser = ({ user }: { user: ExtendedUser }) => {
  const { invitationsQuery, invitationsQueryData } = useInvitation({
    id: user.invitationId,
  });

  const invitation = invitationsQueryData[0];
  return (
    <div className="column">
      <div className="alert alert-secondary" role="alert">
        <span className="me-2 fa-stack"><FontAwesomeIcon icon={faPenToSquare} className="fa-stack-1x"/><FontAwesomeIcon icon={faBan} className="fa-stack-2x"/></span>
        You are unable to edit a user with pending or expired invitations
      </div>
      <UserDetails
        canEdit={false}
        userId={undefined}
        email={invitation?.email}
        firstName={invitation?.firstName}
        surname={invitation?.lastName}
        displayName={invitation?.displayName}
        companyName={invitation?.companyName}
        jobTitle={invitation?.jobTitle}
        department={invitation?.department}
        queryIsLoading={invitationsQuery.isLoading}
      />
      <div className="row">
        <h3 className="anchor-title">Invitation status</h3>

        <div className="d-block mb-4">
          <InvitationStatus user={user} />
        </div>
      </div>

      {/* If user is pending, give ability to generate a new password and copy it */}
      <GenerateInvitation user={user} />

      <div className="row">
        <h3 className="anchor-title">Permissions</h3>
        {invitationsQuery.isLoading && (
          <Spinner animation="border" variant="secondary" role="status">
            <span className="visually-hidden">
              <FormattedMessage id="loading" />
            </span>
          </Spinner>
        )}
        <PermissionsTableCell user={user} />
      </div>
    </div>
  );
};

export default ViewUser;
