import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getInvitation } from "../services/userManagementService";

const useInvitation = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  
  const invitationsQuery = useQuery({
    queryKey: ["invitations", id],
    queryFn: () => getInvitation(id),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const invitationsQueryData = queryClient.getQueryData<any>(["invitations", id]);

  return {
    invitationsQuery,
    invitationsQueryData
  };
};

export default useInvitation;
