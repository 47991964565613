import React from "react";
import useUserDetail from "../../../hooks/useUserDetail";
import EditUser from "../edit-user";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "gatsby-plugin-intl";

const UserDetails = ({
  canEdit,
  userId,
  email,
  firstName,
  surname,
  displayName,
  jobTitle,
  companyName,
  department,
  queryIsLoading,
}: {
  canEdit: boolean;
  userId: string | undefined;
  email: string | undefined | null;
  firstName: string | undefined | null;
  surname: string | undefined | null;
  displayName: string | undefined | null;
  jobTitle: string | undefined | null;
  companyName: string | undefined | null;
  department: string | undefined | null;
  queryIsLoading: boolean;
}) => {

  const { userDetailsData } = useUserDetail({
    userId,
  });
  return (
    <>
      {!canEdit && (
        <div className="row">
          <div className="col-6">
            <h3 className="anchor-title">User Details</h3>

            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Display name</th>
                  <td className={`${queryIsLoading ? "loading-shimmer" : ""}`}>
                    {!queryIsLoading && displayName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">First name</th>
                  <td className={`${queryIsLoading ? "loading-shimmer" : ""}`}>
                    {!queryIsLoading && firstName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last name</th>
                  <td className={`${queryIsLoading ? "loading-shimmer" : ""}`}>
                    {" "}
                    {!queryIsLoading && surname}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>{!queryIsLoading && email}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <h3 className="anchor-title">Job Information</h3>

            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Job title</th>
                  <td className={`${queryIsLoading ? "loading-shimmer" : ""}`}>
                    {!queryIsLoading && jobTitle}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Company name</th>
                  <td className={`${queryIsLoading ? "loading-shimmer" : ""}`}>
                    {!queryIsLoading && companyName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Department</th>
                  <td className={`${queryIsLoading ? "loading-shimmer" : ""}`}>
                    {!queryIsLoading && department}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="row mb-4">
        {queryIsLoading && canEdit ? (
          <Spinner animation="border" variant="secondary" role="status">
            <span className="visually-hidden">
              <FormattedMessage id="loading" />
            </span>
          </Spinner>
        ) : (
          canEdit &&
          userId &&
          userDetailsData && (
            <EditUser
              user={userDetailsData}
              userId={userId}
              email={email}
            />
          )
        )}
      </div>
    </>
  );
};

export default UserDetails;
