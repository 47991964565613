import React, { Dispatch, SetStateAction, useState } from "react";
import InlineFormError from "../form/InlineFormError";
import { useForm } from "react-hook-form";

import { User } from "@microsoft/microsoft-graph-types-beta";
import SwToast from "../toast/sw-toast";
import { useIntl } from "gatsby-plugin-intl";
import useEditUser from "../../hooks/useEditUser";

const EditUser = ({
  userId,
  user,
  email
}: {
  userId: string;
  user: User;
  email: string | undefined | null;
}) => {
  interface UserWithEmail
    extends Pick<
      User,
      | "givenName"
      | "surname"
      | "companyName"
      | "department"
      | "jobTitle"
      | "id"
      | "displayName"
    > {
    email: string | undefined | null;
  }

  const {
    givenName,
    surname,
    companyName,
    department,
    jobTitle,
    displayName,
  } = user;


  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const intl = useIntl();

  const { editUserMutation } = useEditUser({ userId });

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm<UserWithEmail>({
    // validate on submit - button must be enabled for this to work
    mode: "onSubmit",
    // after submit, revalidate form when user completes a field
    reValidateMode: "onBlur",
    defaultValues: {
      givenName,
      surname,
      companyName,
      department,
      jobTitle,
      displayName,
      id: userId,
    },
  });

  const onSubmit = (
    data: UserWithEmail
  ) => {
    unregister("email");
    // Entra api doesn't accept empty strings
    const replaceEmptyStringsWithNull = (dataObj: any) =>
      Object.fromEntries(
        Object.entries(dataObj).map(([k, v], i) => [k, v === "" ? null : v])
      );

     
    const updatedUser = replaceEmptyStringsWithNull(data);
    editUserMutation.mutate(updatedUser, {
      onSuccess(data) {
        if (data?.status === 200) {
          setShowSuccessToast(true);
        } else {
          setShowErrorToast(true);
        }
      },
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="px-3"
      >
        <div className="row">
          <fieldset className="rounded px-2 mb-3 col-6">
            <legend className="anchor-title">User details</legend>
            <div className="mb-1 p-2">
              <label htmlFor="givenName" className="form-label fw-bold">
                Display name (required)
              </label>
              <input
                className="form-control"
                {...register("displayName", { required: true })}
              />
              {errors.displayName?.type === "required" && (
                <InlineFormError message="Display name is required" />
              )}
            </div>
            <div className="mb-1 p-2">
              <label htmlFor="givenName" className="form-label fw-bold">
                First name (required)
              </label>
              <input
                className="form-control"
                {...register("givenName", { required: true })}
              />
              {errors.givenName?.type === "required" && (
                <InlineFormError message="First name is required" />
              )}
            </div>
            <div className="mb-1 p-2">
              <label htmlFor="surname" className="form-label fw-bold">
                Last name (required)
              </label>
              <input
                className="form-control"
                autoComplete=""
                {...register("surname", { required: true })}
              />
              {errors.surname?.type === "required" && (
                <InlineFormError message="Last name is required" />
              )}
            </div>
            <div className="mb-1 p-2">
              <label htmlFor="email" className="form-label fw-bold">
                Email
              </label>
              <input
                className="form-control"
                autoComplete=""
                disabled
                {...register("email")}
                defaultValue={email ? email : ""}
              />
            </div>
          </fieldset>
          <fieldset className="rounded px-2 mb-3 col-6">
            <legend className="anchor-title">Job information</legend>
            <div className="mb-1 p-2">
              <label htmlFor="jobTitle" className="form-label fw-bold">
                Job title
              </label>
              <input className="form-control" {...register("jobTitle")} />
            </div>
            <div className="mb-1 p-2">
              <label htmlFor="companyName" className="form-label fw-bold">
                Company name
              </label>
              <input className="form-control" {...register("companyName")} />
            </div>
            <div className="mb-1 p-2">
              <label htmlFor="department" className="form-label fw-bold">
                Department
              </label>
              <input className="form-control" {...register("department")} />
            </div>
          </fieldset>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      <SwToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        header={intl.formatMessage({
          id: "user.edit.success",
          defaultMessage: "User edited",
        })}
        body={intl.formatMessage({
          id: "user.edit.success.message",
          defaultMessage: "User details were successfully edited",
        })}
      ></SwToast>
      <SwToast
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        header={intl.formatMessage({
          id: "error",
        })}
        body={intl.formatMessage({
          id: "There was an error editing this user",
        })}
      ></SwToast>
    </>
  );
};

export default EditUser;
