import React, { useState } from "react";
import { Button, Offcanvas, Spinner } from "react-bootstrap";
import UsersTable from "./users-table";
import PermissionsTableCell from "./permissions-table-cell";
import usePPPUsers from "../../hooks/users/usePPPUsers";
import { FormattedMessage } from "gatsby-plugin-intl";
import InvitationStatus from "./invitation-status";
import { ExtendedUser } from "../../types/user-management";
import ViewUserEditable from "./view-user/view-user-editable";
import ViewUser from "./view-user/view-user";

function UsersList({ searchName }: { searchName: string | undefined }) {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState<ExtendedUser | undefined>();
  const handleClose = () => setShow(false);

  const openOffCanvas = (user: ExtendedUser | undefined) => {
    if (!user) {
      return;
    }
    setShow(true);
    setUser(user);
  };

  const { pppUsersQuery } = usePPPUsers();

  const columns = [
    {
      id: "name",
      header: "Name",
      cell: ({ row }: { row: any }) => {
        const user: ExtendedUser = row.original;
        if (!user.displayName) {
          return "";
        }
        return <span>{user.displayName}</span>;
      },
      accessorKey: "displayName",
      enableColumnFilter: true,
    },
    {
      id: "email",
      header: "Email",
      cell: ({ row }: { row: any }) => {
        const user: ExtendedUser = row.original;
        if (user.identities && !user.identities[0]?.issuerAssignedId) {
          return "";
        }
        return (
          <span>{user.identities && user.identities[0]?.issuerAssignedId}</span>
        );
      },
      accessorFn: (row: any) => {
        const identity: any = row.identities[0];
        return `${identity.issuerAssignedId}`;
      },
    },
    {
      id: "invitation-status",
      header: "Invitation status",
      cell: ({ row }: { row: any }) => {
        const user: ExtendedUser = row.original;
        return <InvitationStatus user={user} />;
      },
      accessorKey: "signInActivity",
    },
    {
      id: "permissions",
      header: "Permissions",
      cell: ({ row }: { row: any }) => {
        const user: ExtendedUser = row.original;
        if (!user) {
          return "";
        }
        return <PermissionsTableCell user={user} />;
      },
      accessorKey: "permissions",
      enableSorting: false,
    },
    {
      id: "company",
      header: "Company",
      cell: ({ row }: { row: any }) => {
        const user: ExtendedUser = row.original;
        if (!user.companyName) {
          return "";
        }
        return <span>{user.companyName}</span>;
      },
      accessorKey: "companyName",
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }: { row: any }) => {
        const user: ExtendedUser = row.original;
        if (user.id === undefined && user.invitationId === undefined) {
          return <Button className="btn btn-secondary disabled">View</Button>;
        } else {
          return (
            <Button
              onClick={() => openOffCanvas(user)}
              className="btn btn-secondary"
            >
              View
            </Button>
          );
        }
      },
      filterType: undefined,
    },
  ];

  if (pppUsersQuery.isLoading) {
    return (
      <div className="row mb-5">
        <div className="col">
          <h2 className="anchor-title">
            <FormattedMessage id="loading" />{" "}
          </h2>
          <Spinner animation="border" variant="secondary" role="status">
            <span className="visually-hidden">
              <FormattedMessage id="loading" />
            </span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card">
        <UsersTable
          usersQuery={pppUsersQuery}
          columns={columns}
          searchName={searchName}
        />
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={"end"}
        className="offcanvas-subitem w-75"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h2>View user</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {user && (!!user.id ? <ViewUserEditable user={user} handleClose={handleClose}/> : <ViewUser user={user}/>)}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default UsersList;
