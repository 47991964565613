import React, { useState } from "react";
import UserDetails from "./user-details";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Spinner } from "react-bootstrap";
import EditPermissions from "../edit-permissions";
import InvitationStatus from "../invitation-status";
import PermissionsTableCell from "../permissions-table-cell";
import useUserDetail from "../../../hooks/useUserDetail";
import { ExtendedUser } from "../../../types/user-management";

const ViewUserEditable = ({ user, handleClose }: { user: ExtendedUser, handleClose: () => void }) => {
  const [showPermissionsEdit, setShowPermissionsEdit] =
    useState<boolean>(false);

  const { userDetailQuery, userDetailsData } = useUserDetail({
    userId: user.id,
  });


  return (
    <div className="column mb-5">
      <UserDetails
        canEdit={true}
        userId={user.id}
        email={userDetailsData?.identities[0]?.issuerAssignedId}
        firstName={userDetailsData?.givenName}
        surname={userDetailsData?.surname}
        displayName={userDetailsData?.displayName}
        companyName={userDetailsData?.companyName}
        jobTitle={userDetailsData?.jobTitle}
        department={userDetailsData?.department}
        queryIsLoading={userDetailQuery.isFetching}
      />
      <div className="row">
        <h3 className="anchor-title">Invitation status</h3>

        <div className="d-block mb-4">
          <InvitationStatus user={{ ...userDetailsData, invitationId: "" }} />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <h3 className="anchor-title">Permissions</h3>
          {userDetailQuery.isLoading && (
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          )}
          {userDetailsData && (
            <EditPermissions
              user={{ ...userDetailsData, invitationId: "" }}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewUserEditable;
