import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addGroupMember,
  removeGroupMember,
} from "../services/userManagementService";

const useMutateUserGroups = (userId: string) => {
  const queryClient = useQueryClient();

  const addGroupMemberMutation = useMutation({
    mutationKey: ['mutate-group-member', userId, 'add'],
    mutationFn: (groupId: string) => {
      return addGroupMember(userId, groupId)
        .then((response) => {
          if (response.ok) {
            queryClient.invalidateQueries({
              queryKey: ["groups-members", groupId],
              exact: true
            });

            queryClient.invalidateQueries({
              queryKey: ["user-groups", userId],
              exact: true
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  const removeGroupMemberMutation = useMutation({
    mutationKey: ['mutate-group-member', userId, 'remove'],
    mutationFn: (groupId: string) => {
      return removeGroupMember(userId, groupId)
        .then((response) => {
          if (response.ok) {
            queryClient.invalidateQueries({
              queryKey: ["groups-members", groupId],
              exact: true
            });

            queryClient.invalidateQueries({
              queryKey: ["user-groups", userId],
              exact: true
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });

  return {
    addGroupMemberMutation,
    removeGroupMemberMutation,
  };
};

export default useMutateUserGroups;
